.sidebar{
    width: 10%;
    height: 100vh;
    background-color: black;
    float: left;
}
.sidebar ul{
    position: relative;
    top: 25%;
    left: 30%;
}
.sidebar ul li{
    margin-bottom: 30px;
    list-style: none;
}
.sidebar img{
    width: 50px;
}












@media only screen and (max-width:480px){
    .sidebar{
        width: 100%;
        height: 100px;

    }
    .sidebar ul li{
        display: inline-block;
        margin-right: 70px;
    }
    .sidebar ul{
        position: relative;
        top: 30px;
        left: 50px;
    }
}


@media only screen and (min-width:480px) and (max-width:768px){
    .sidebar{
        width: 100%;
        height: 100px;

    }
    .sidebar ul li{
        display: inline-block;
        margin-right: 70px;
    }
    .sidebar ul{
        position: relative;
        top: 30px;
        left: 180px;
    }
}
.women figure{
    width: 30%;
    height: 220px;
    margin: 10px;
}
.women figure img{
    width: 100%;
    height: 100%;
    margin-top: 40px;
}
.women figure:hover img{
    transform: scale3d(1.05,1.05,1.05);
}
.women{
    margin: 20px;
}


@media only screen and (max-width:480px){
    .women{
        width: 100%;
        height: 100vh;
    }

    .women figure{
        width: 43%;
        height: 220px;
        margin: 10px;
    }


}

@media only screen and (min-width:480px) and (max-width:768px){
    .women{
        width: 100%;
        height: 100vh;
    }
    .women figure{
        width: 27%;
    }


    @media only screen and (min-width:614px) and (max-width:768px){

.women figure{
        width: 43%;
    }
    }

}

@media only screen and (min-width:614px) and (max-width:768px){
    
}


@media only screen and (min-width:768px) and (max-width:992px){
    .women figure{
        width: 42%;
    }
    .women {
        height: auto;
    }

}

@media only screen and (min-width:992px) and (max-width:1200px){
    .women figure{
        width: 45%;
    }
    .women {
        height: auto;
    }
}
@media only screen and (min-width:1200px) and (max-width:1280px){
    .women figure{
        width: 30%;
    }
}
.navbar{
    width: 50%;
    float: left;
    overflow: hidden;
}

.navbar li{
  list-style: none;
  float: left;
  margin: 50px 50px 0 80px;
}
.navbar li a{
    text-decoration: none;
    font-weight: 900; 
    color: black;
}

@media only screen and (max-width:480px){
    .navbar{
        width: 100%;
        height: auto;

    }
    .navbar li{
       
       
        margin: 50px 10px 80px 50px;
      }
}

@media only screen and (min-width:480px) and (max-width:768px){
    .navbar{
        width: 100%;
      

    }
    .navbar li{
       
       
        margin: 50px 30px 80px 60px;
      }
}

@media only screen and (min-width:768px) and (max-width:992px){
   

    .navbar li{
       
       
        margin: 50px 20px 80px 30px;
      }
}


@media only screen and (min-width:992px) and (max-width:1096px){
    .navbar li{
       
       
        margin: 50px 20px 80px 30px;
      }
}
.picture{
    width: 40%;
    height: 100vh;
    
    float: left;
}
.picture img{
    width: 100%;
    height: 100%;
}
@media only screen and (max-width:480px){
    .picture{
        width: 100%;
height: auto;
    }
}
@media only screen and (min-width:480px) and (max-width:768px){
    .picture{
        width: 100%;
height: 100vh;
    }
}
.men{
    width: 100%;
    height: 100vh;
    background-color:rgb(231, 225, 225);
}


.box{
    width: 27%;
    position: relative;
    top: 20%;
    left: 13%;
}
.box2{
  
position: relative;
top:30%;
left: 5%;
}
figure{
    width: 30%;
    height:300px;
float: left;
}
figure img{
    width: 100%;
    height: 100%;
    
}
.box2::after{
    content: '';
    display: table;
    clear: both;
}

@media only screen and (max-width:480px){
    .men{
        width: 100%;
        height: 100vh;

    }
    .box{
        width: 47%;
        position: relative;
        top: 0;
        left: 13%;
    }

    .box2{
  
        position: relative;
        top:10%;
        left: 5%;
        }
}

@media only screen and (min-width:480px) and (max-width:768px){
    .men{
        width: 100%;
      

    }
    .box{
        width: 47%;
        position: relative;
        top: 0;
        left: 13%;
    }

    .box2{
  
        position: relative;
        top:10%;
        left: 5%;
        }

}



@media only screen and (min-width:768px) and (max-width:992px){
    .men{
        width: 100%;
      

    }
    .box{
        width: 47%;
        position: relative;
        top: 0;
        left: 13%;
    }

    .box2{
  
        position: relative;
        top:10%;
        left: 5%;
        }

}












.main{
    width: 100%;
    height: 100vh;
background-color:rgb(231, 225, 225);
}

.box{
    width: 27%;
    position: relative;
    top: 20%;
    left: 13%;
}
.box2{
    width: 70%;
position: relative;
top:30%;
left: 13%;
}
.box2 p{
    line-height: 30px;
    color: rgb(78, 73, 73);
    width: 80%;
}
.box2 h1{
    font-size: 80px;
}
















@media only screen and (max-width:480px){
    .main{
        width: 100%;
       

    }
    
    
    .box{
        width: 47%;
        top: 0;
        left: 25%;
    }
    

    .box2{
        
        width: 100%;
    
    top:20%;
    left: 13%;
    }
    .box2 p{
        font-size: 14px;
        width: 83%;
    }
    .box2 h1{
        font-size: 40px;
    }
    
}

@media only screen and (min-width:480px) and (max-width:768px){
    .box{
        width: 47%;
        top: 0;
        left: 25%;
    }
    

    .box2{
        
        width: 100%;
    
    top:20%;
    left: 13%;
    }
    .box2 p{
        font-size: 14px;
        width: 83%;
    }
    .box2 h1{
        font-size: 40px;
    }
}




@media only screen and (min-width:768px) and (max-width:992px){
    .box{
        width: 47%;
        top: 0;
        left: 25%;
    }
    

    .box2{
        
        width: 100%;
    
    top:20%;
    left: 13%;
    }
    .box2 p{
        font-size: 14px;
        width: 83%;
    }
    .box2 h1{
        font-size: 40px;
    }
    
}


@media only screen and (min-width:992px) and (max-width:1200px){
    .box2 h1{
        font-size: 30px;
    }


}